/* --- CONTAINERS --- */
.about-row {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100%;
  position: relative;
}

.about-col {
  display: flex;
  flex-direction: column;
}

.about-img-container {
  display: flex;
  flex-direction: row;
}

.about-txt-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 712px;
  transform: translate(0%, -50%);
  width: 30%;
  background-color: rgba(255, 255, 255, 0.7);
}

/* --- TEXT --- */

/* --- IMAGES/ICONS --- */
.about-img {
  display: flex;
  width: 100%;
}

/* --- BUTTONS --- */

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 430px) {
  /* --- CONTAINERS --- */
  .about-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .about-img-container {
    width: 100%;
  }

  .about-txt-container {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */
  .about-img {
    width: 100%;
  }

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */
  .about-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .about-img-container {
    width: 100%;
  }

  .about-txt-container {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */
  .about-img {
    width: 100%;
  }

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
