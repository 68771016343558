/* --- CONTAINERS --- */
.footer-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footer-form {
  margin-top: 5%;
  width: 90%;
}

.footer-button-wrapper {
  margin-top: 40px;
}

.footer-ref-cont {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 40px;
}

/* --- TEXT --- */
.footer-ref-txt {
  font-size: 1.2rem;
  color: var(--manhattan);
}

/* --- IMAGES/ICONS --- */
.footer-ref-img {
  width: 100%;
}

/* --- BUTTONS --- */

.footer-submit-button {
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

.footer-submit-button:hover {
  background-color: var(--manhattanHover);
  border: solid 1px var(--manhattan);
  color: var(--richBlack);
}

.ftr-ctrl-selector {
  border: solid 2px var(--blueBerry);
  color: var(--richBlack);
  border-radius: 10px;
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
