.side-menu-container-open {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-height: 100%;
  background-color: rgba(0, 71, 171, 0.9); /* cobalt blue */
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.side-menu-container-closed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 71, 171, 0.9);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.side-menu-toggle-controls-open {
  display: flex;
  justify-content: flex-end;
  padding: 2%;
  position: absolute;
  top: 7px;
  left: 80%;
  z-index: 2;
}

.side-menu-toggle-controls-closed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2%;
}

.side-menu-toggle-icon {
  width: 30px;
  cursor: pointer;
}

.side-menu-icon {
  width: 20px;
  padding: 0.5%;
  margin-left: 1%;
}

.side-menu-links-container {
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 2%;
  margin-top: 10%;
  position: absolute;
  z-index: 2;
  /* outline: solid 2px green; */
}

.side-menu-link-and-icon-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 2%;
  padding: 2%;
}

.link-side {
  width: 50%;
}

.icon-side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
}

.sub-link-group-wrapper {
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--white);
  margin-left: 5%;
}

.side-menu-link-span {
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
}

.side-menu-link {
  color: var(--white);
  font-weight: 600;
  text-decoration: none;
}

.side-menu-solo-link {
  color: var(--white);
  font-weight: 600;
  text-decoration: none;
  padding: 2%;
  margin-left: 2%;
  cursor: pointer;
}

.side-menu-sub-link {
  font-weight: 300;
  padding: 2%;
}

.side-menu-link:hover {
  color: var(--white);
  transform: scale(1.05);
}

.side-menu-link-span:hover {
  color: var(--white);
  transform: scale(1.05);
}

.side-menu-link-icon-wrapper .link-side .side-menu-link:hover {
  color: var(--white);
  transform: scale(1.05);
}

.side-menu-solo-link:hover {
  color: var(--white);
  transform: scale(1.05);
}

.side-menu-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 1%;
  cursor: pointer;
}

.side-menu-logo-link {
  text-align: center;
}

.side-menu-logo {
  width: 60%;
}

.side-menu-closed-logo {
  width: 14%;
  padding: 2%;
}

.clickable {
  cursor: pointer;
}

.side-menu-closed-wrapper {
  display: flex;
  width: 100%;
}

.side-menu-closed-left {
  display: flex;
  align-items: center;
  width: 50%;
}

.side-menu-closed-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.side-menu-new-apps-info-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.side-menu-new-apps-count-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mexicanRed);
  border: solid 1px var(--mexicanRed);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.side-menu-new-rma-count-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--manhattan);
  border: solid 1px var(--manhattan);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.side-menu-new-surveys-count-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--blueBerry);
  background-color: var(--blueBerry);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.dark-text {
  font-weight: 500;
  color: #000;
}

.light-text {
  font-weight: 500;
  color: #fff;
}

.side-menu-text {
  font-weight: 300;
  color: var(--white);
}

.side-menu-text-bold {
  font-weight: 500;
  color: var(--white);
}

.side-menu-margin-left-20 {
  margin-left: 20px;
}

.side-menu-admin-link {
  text-decoration: none;
}

/* ----------------------- ANIMATION --------------------- */
.animate-open {
  animation-name: animate-open;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-duration: 0.3s;
}

@keyframes animate-open {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.animate-close {
  animation-name: animate-close;
  animation-timing-function: cubic-bezier(1.48, 0.74, 0.53, 0.26);
  animation-duration: 0.3s;
}

@keyframes animate-close {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

/* ----------------------- MOBILE SECTION --------------------- */
@media only screen and (max-width: 1280px) {
  .side-menu-container-open {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
}

@media only screen and (max-width: 700px) {
  .side-menu-container-open {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  .side-menu-link {
    color: var(--white);
    font-weight: 400;
    text-decoration: none;
  }

  .side-menu-solo-link {
    color: var(--white);
    font-weight: 400;
    text-decoration: none;
    padding: 2%;
    margin-left: 2%;
  }

  .side-menu-sub-link {
    font-weight: 300;
    padding: 2%;
  }

  .side-menu-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }

  .side-menu-link-icon-wrapper .link-side .side-menu-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }

  .side-menu-solo-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }
}

/* --- MOBILE VIEW --- */
@media only screen and (max-width: 600px) {
  .side-menu-container-open {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }

  .side-menu-link {
    color: var(--white);
    font-weight: 400;
    text-decoration: none;
  }

  .side-menu-solo-link {
    color: var(--white);
    font-weight: 400;
    text-decoration: none;
    padding: 2%;
    margin-left: 2%;
  }

  .side-menu-sub-link {
    font-weight: 300;
    padding: 2%;
  }

  .side-menu-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }

  .side-menu-link-icon-wrapper .link-side .side-menu-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }

  .side-menu-solo-link:hover {
    color: var(--white);
    transform: scale(1.05);
  }

  .side-menu-closed-logo {
    width: 40%;
  }
}
