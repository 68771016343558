/* --- CONTAINERS --- */

.home-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.home-col {
  display: flex;
  flex-direction: column;
}

.home-text-col {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-img-col {
  justify-content: center;
}

.hr-two {
  margin-top: 5%;
}

.hr-one .home-text-col {
  /* align-items: flex-end; */
  align-items: center;
}

.hr-one .home-img-col {
  align-items: flex-start;
}

.hr-two .home-text-col {
  align-items: flex-start;
}

.hr-two .home-img-col {
  align-items: flex-end;
}

.hpw-one {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  padding: 1rem;
  border: solid 1px rgba(155, 155, 155, 0.6);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}

.hpw-two {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(-50%, -50%);
  width: 30%;
  padding: 1rem;
  border: solid 1px rgba(155, 155, 155, 0.6);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}

/* --- TEXT --- */

/* --- IMAGES --- */
.home-img {
  width: 100%;
}

/* --- BUTTONS --- */

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1800px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 800px;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    right: 400px;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1400px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 850px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 400px;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    right: 200px;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hr-one {
    margin-top: 15%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hr-one {
    margin-top: 15%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */
  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 430px) {
  /* --- CONTAINERS --- */
  .home-row {
    margin-left: 0px;
  }

  .hr-one {
    margin-top: 30%;
  }

  .hr-two {
    margin-top: 5%;
  }

  .hr-one .home-text-col {
    align-items: center;
  }

  .hr-one .home-img-col {
    align-items: flex-start;
  }

  .hr-two .home-text-col {
    align-items: flex-start;
  }

  .hr-two .home-img-col {
    align-items: flex-end;
  }

  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */
  .home-row {
    margin-left: 0px;
  }

  .hr-one {
    margin-top: 30%;
  }

  .hr-two {
    margin-top: 5%;
  }

  .hr-one .home-text-col {
    align-items: center;
  }

  .hr-one .home-img-col {
    align-items: flex-start;
  }

  .hr-two .home-text-col {
    align-items: flex-start;
  }

  .hr-two .home-img-col {
    align-items: flex-end;
  }

  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */
  .home-row {
    margin-left: 0px;
  }

  .hr-one {
    margin-top: 30%;
  }

  .hr-two {
    margin-top: 5%;
  }

  .hr-one .home-text-col {
    align-items: center;
  }

  .hr-one .home-img-col {
    align-items: flex-start;
  }

  .hr-two .home-text-col {
    align-items: flex-start;
  }

  .hr-two .home-img-col {
    align-items: flex-end;
  }

  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */
  .home-row {
    margin-left: 0px;
  }

  .hr-one {
    margin-top: 30%;
  }

  .hr-two {
    margin-top: 5%;
  }

  .hr-one .home-text-col {
    align-items: center;
  }

  .hr-one .home-img-col {
    align-items: flex-start;
  }

  .hr-two .home-text-col {
    align-items: flex-start;
  }

  .hr-two .home-img-col {
    align-items: flex-end;
  }

  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 360px) {
  /* --- CONTAINERS --- */
  .home-row {
    margin-left: 0px;
  }

  .hr-one {
    margin-top: 30%;
  }

  .hr-two {
    margin-top: 5%;
  }

  .hr-one .home-text-col {
    align-items: center;
  }

  .hr-one .home-img-col {
    align-items: flex-start;
  }

  .hr-two .home-text-col {
    align-items: flex-start;
  }

  .hr-two .home-img-col {
    align-items: flex-end;
  }

  .hpw-one {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .hpw-two {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 1rem;
    border: solid 1px rgba(155, 155, 155, 0.6);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
