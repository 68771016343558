/* --- CONTAINERS --- */
.ilm-cont {
  display: flex;
  flex-direction: column;
}

.ilm-ul {
  list-style: none;
}

.ilm-li {
  border-top: solid 2px #000;
  border-left: solid 2px #000;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: rgb(38, 57, 77) 0px 10px 20px -10px;
  border-radius: 10px;
  cursor: pointer;
}

.ilm-li:hover {
  background-color: #f2f2f2;
  transform: scale(1.02);
}

/* --- TEXT --- */
.ilm-a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
}

/* --- IMAGES/ICONS --- */

/* --- BUTTONS --- */

@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
