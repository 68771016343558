/* --- CONTAINERS --- */
.acv-container {
  display: flex;
  flex-direction: column;
}

.acv-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.acv-col {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.acv-left {
  border-right: solid 2px black;
  height: 100vh;
  align-items: center;
}

.post-viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: solid 1px black;
  padding: 1rem;
  margin-bottom: 10px;
}

.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.post-body {
  display: flex;
  flex-direction: column;
}

.post-text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.post-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  width: 100%;
}

.post-options-container {
  display: flex;
  justify-content: space-between;
}

.page-cont-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  overflow: scroll;
}

.pg-cont-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ddd;
}

.pg-cont-col {
  display: flex;
  flex-direction: column;
}

.pg-txt-cont {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.home-ref-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

/* --- TEXT --- */
.pg-title-txt {
  font-size: 1rem;
  font-weight: bold;
}

.pg-cont-sub-txt {
  font-size: 0.8rem;
  margin-left: 5px;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-top-width: thin;
}

.pg-para-title {
  font-size: 0.9rem;
  font-weight: bold;
  color: blue;
}

/* --- BORDERS --- */

/* --- IMAGES/ICONS --- */
.post-img {
  width: 9rem;
  margin-left: 2px;
  margin-right: 2px;
}

.acv-trash-icon {
  cursor: pointer;
  width: 20px;
  margin-left: 40px;
  cursor: pointer;
}

.acv-edit-icon {
  cursor: pointer;
  width: 20px;
  margin-left: 40px;
  cursor: pointer;
}

.home-ref-img {
  width: 80%;
}

/* --- TEXT --- */
.activity-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--contessa);
}

.post-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.post-page {
  font-size: 1.1rem;
}

.home-ref-txt {
  font-size: 1.1rem;
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
