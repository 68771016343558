/* --- CONTAINERS --- */
.contact-card-container {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
}

.contact-card-container-full-width {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.contact-card-row {
  display: flex;
  width: 100%;
}

.contact-card-icon-row {
  display: flex;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}

.contact-card-info-row {
  display: flex;
  margin-left: 10px;
}

.contact-card-col {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

/* --- TEXT --- */
.contact-card-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #fff;
}

.contact-card-txt {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #fff;
}

.contact-card-txt-sm {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #fff;
}

.contact-card-txt-bld {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #fff;
}

.contact-card-txt-sm-bld {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #fff;
}

.contact-card-anchor {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.contact-card-anchor-bld {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #fff;
}

.contact-card-ul {
  list-style: none;
  padding: 0;
}

.contact-card-li {
  font-size: 0.7rem;
  font-weight: 300;
  color: #fff;
}

/* --- IMAGES/ICONS --- */
.contact-card-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
}

/* --- BUTTONS --- */

@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */
  .contact-card-container {
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
