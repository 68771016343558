/* --- CONTAINERS --- */
.cont-box-container {
  margin-bottom: 1rem;
  width: 80%;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.cont-box-container-sm {
  margin: 10px;
}

.cont-box-sub-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cont-box-sub-cont-sm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cont-box-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cont-box-row-sm {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cont-box-col {
  display: flex;
  flex-direction: column;
}

.cont-box-col-sm {
  display: flex;
  flex-direction: column;
}

.cont-text-col {
  padding: 1rem;
}

.cont-text-col-sm {
  padding: 1rem;
}

.cont-img-col {
  padding: 1rem;
}

.cont-img-col-sm {
  padding: 1rem;
}

.cb-img-area {
  display: flex;
  overflow: scroll;
  width: 100%;
  cursor: pointer;
}

.cb-img-area-sm {
  display: flex;
  overflow: scroll;
  width: 100%;
  cursor: pointer;
}

.cont-img-wrapper {
  display: flex;
  margin-left: 3px;
  margin-right: 3px;
  width: fit-content;
}

.cont-img-wrapper-sm {
  display: flex;
  margin-left: 3px;
  margin-right: 3px;
  width: fit-content;
}

/* --- TEXT --- */
.cont-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.cont-title-sm {
  font-size: 1.5rem;
  font-weight: 600;
}

.cont-price {
  font-size: 1rem;
  font-weight: 400;
}

.cont-price-sm {
  font-size: 1rem;
  font-weight: 400;
}

/* --- IMAGES --- */
.cont-img {
  width: 10rem;
}

.cont-img-sm {
  width: 10rem;
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 430px) {
  /* --- CONTAINERS --- */
  .cont-box-container {
    width: 100%;
  }

  .cont-img-col {
    padding: 1rem;
    overflow: visible;
    width: 100%;
  }

  .cb-img-area {
    display: flex;
    width: 100%;
  }

  .cont-img-wrapper {
    display: flex;
    margin-left: 3px;
    margin-right: 3px;
    width: fit-content;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */
  .cont-box-container {
    width: 100%;
  }

  .cont-img-col {
    padding: 1rem;
    overflow: visible;
    width: 100%;
  }

  .cb-img-area {
    display: flex;
    width: 100%;
  }

  .cont-img-wrapper {
    display: flex;
    margin-left: 3px;
    margin-right: 3px;
    width: fit-content;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
