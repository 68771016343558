/* --- CONTAINERS --- */

.edit-post-modal-container {
  display: flex;
  flex-direction: column;
}

.edit-post-modal-text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.edit-post-modal-img-area {
  display: flex;
  flex-wrap: wrap;
}

.edit-post-modal-img-container {
  display: flex;
  margin-right: 15px;
  margin-bottom: 2px;
  position: relative;
}

.cont-cont-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.cont-title-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.edit-cont-title-input {
  margin-bottom: 1rem;
}

.edit-cont-cont-input {
  height: 5rem;
  margin-bottom: 1rem;
}

/* --- TEXT --- */
.warning-text {
  color: red;
}

.activity-text {
  color: #4a4a4a;
}

.edit-post-modal-label {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

/* --- IMAGES/ICONS --- */
.edit-post-modal-img {
  width: 5rem;
}

.modal-img-viewer-only {
  width: 100%;
}

.edit-post-modal-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 2px;
  border: solid 1px #000;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 45px;
  left: 65px;
}

.edit-post-modal-icon:hover {
  transform: scale(1.1);
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
