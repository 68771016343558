.loading-spinner-container {
  display: flex;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  background: #fff;
}
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 24px solid #00d5ff;
  animation: prixClipFix 2s infinite linear;
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
