/* --- CONTAINERS --- */
.footer-cont {
  display: flex;
  background-color: var(--cobaltBlue);
  width: 100%;
  min-height: 250px;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-contact-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: flex-start; */
}

.footer-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-li {
  margin: 0;
  padding: 0;
}

/* --- TEXT --- */
.footer-txt {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin: 2px;
  padding: 2px;
}

.footer-contact-txt {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #fff;
}

.footer-contact-txt-sm {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  color: #fff;
}

.footer-contact-anchor {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

/* --- IMAGES/ICONS --- */
.footer-logo {
  width: 10rem;
  /* height: 4rem; */
  aspect-ratio: 16/9;
  padding: 5px;
}

.footer-img {
  width: 9rem;
  /* height: 4rem; */
  aspect-ratio: 16/9;
  padding: 5px;
}

.footer-icon {
  width: 50px;
  height: 50px;
  margin: 5px;
  padding: 5px;
}

.footer-contact-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 430px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .footer-contact-txt-sm {
    font-size: 0.5rem;
  }

  /* --- IMAGES/ICONS --- */
  .footer-img {
    width: 6rem;
  }

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .footer-contact-txt-sm {
    font-size: 0.5rem;
  }

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .footer-contact-txt-sm {
    font-size: 0.5rem;
  }

  /* --- IMAGES/ICONS --- */
  .footer-img {
    width: 5rem;
  }

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .footer-contact-txt-sm {
    font-size: 0.5rem;
  }

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
