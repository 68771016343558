/* --- CONTAINERS --- */
.banner-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.banner-img-cont {
  width: 100%;
  position: relative;
}

.banner-sub-area {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: -84px;
  left: 0px;
}

.banner-sub-cont {
  display: flex;
  justify-content: center;
  width: 25%;
  border: solid 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.banner-txt-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  flex-shrink: calc(1 / 3);
}

.banner-sub-txt-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
}

.banner-sub-img-cont {
  position: relative;
  width: 100%;
}

.banner-txt-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 15px;
  left: 35px;
  flex-shrink: calc(1 / 3);
}

/* --- TEXT --- */
.banner-h1 {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.banner-h2 {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 2px black;
}

.banner-sub-txt {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 2px black;
}

/* --- IMAGES --- */
.banner-img {
  width: 100%;
  aspect-ratio: 1/0.5;
}

.banner-sub-img {
  width: 100%;
  height: 100%;
}

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .banner-sub-txt {
    font-size: 0.7rem;
  }

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .banner-sub-txt {
    font-size: 0.7rem;
  }

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */

  /* --- TEXT --- */
  .banner-sub-txt {
    font-size: 0.7rem;
  }

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
