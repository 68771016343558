/* --- CONTAINERS --- */
.contact-row {
  display: flex;
  width: 100%;
  min-height: 600px;
}

.contact-col {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
}

.contact-img-container {
  width: 100%;
}

.contact-page-form-container {
  display: flex;
  position: absolute;
  top: 40%;
  left: 60rem;
  transform: translate(0%, -40%);
  width: 30%;
}

/* --- TEXT --- */

/* --- IMAGES/ICONS --- */
.contact-img {
  width: 100%;
}

/* --- BUTTONS --- */

/* --- MOBILE VIEW --- */
@media screen and (max-width: 1474px) {
  /* --- CONTAINERS --- */
  .contact-page-form-container {
    display: flex;
    position: absolute;
    top: 40%;
    left: 50rem;
    transform: translate(0%, -40%);
    width: 30%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1240px) {
  /* --- CONTAINERS --- */
  .contact-page-form-container {
    display: flex;
    position: absolute;
    top: 40%;
    left: 40rem;
    transform: translate(0%, -40%);
    width: 30%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 1000px) {
  /* --- CONTAINERS --- */
  .contact-page-form-container {
    display: flex;
    position: absolute;
    top: 40%;
    left: 30rem;
    transform: translate(0%, -40%);
    width: 30%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 750px) {
  /* --- CONTAINERS --- */
  .contact-page-form-container {
    display: flex;
    position: absolute;
    top: 40%;
    left: 20rem;
    transform: translate(0%, -40%);
    width: 30%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 700px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 430px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 393px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 390px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}

@media screen and (max-width: 375px) {
  /* --- CONTAINERS --- */
  .contact-col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-page-form-container {
    display: flex;
    position: relative;
    top: 0%;
    left: 0rem;
    transform: translate(0%, 0%);
    margin-top: 10px;
    width: 100%;
  }

  /* --- TEXT --- */

  /* --- IMAGES/ICONS --- */

  /* --- BUTTONS --- */
}
